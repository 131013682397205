<template>
    <div>
    <a-modal :width="680" :visible.sync="visible" @cancel="handleCancel" title="新建项目">
        <a-spin :spinning="loading" tip="正在提交...">
            <a-form-model ref="formRef" :model="formData" style="width: 95%;margin: 0 auto;" :colon="true" :labelCol="{
                xs: { span: 24 },
                sm: { span: 6 },
            }" :wrapper-col="{ span: 18 }" :rules="rules">
                <a-form-model-item label="课题组" prop="researchGroupId">
                    <a-select v-model="formData.researchGroupId" placeholder="请选择" show-search :filter-option="filterOption"
                        @change="handleResearchGroupChange">
                        <a-select-option v-for="(team, index) in researchGroupList" :key="team.id">
                            {{ team.name }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="负责人" prop="headUserId">
                    <a-spin :spinning="isHeadListLoading">
                        <a-select :loading="isHeadListLoading" v-model="formData.headUserId" placeholder="请选择" @change="handleHeadChange">
                            <a-select-option v-for="(user, index) in headList" :key="user.userId">
                                {{ user.name }}
                                <!--  <template slot="notFoundContent">
                                </a-spin>
                            </template> -->
                            </a-select-option>
                        </a-select>
                    </a-spin>
                </a-form-model-item>
                <a-form-model-item label="项目名称" prop="name">
                    <a-input v-model="formData.name" placeholder="请输入"></a-input>
                </a-form-model-item>
                <a-form-model-item label="（拟/已）投稿期刊" prop="magazine">
                    <a-input v-model="formData.magazine" placeholder="请输入"></a-input>
                </a-form-model-item>
                <a-form-model-item label="研究目的" prop="objective">
                    <a-textarea :autoSize="{ minRows: 4 }" v-model="formData.objective" :maxLength="200"
                        placeholder="请输入（最多200个字）" />
                </a-form-model-item>
                <a-form-model-item label="预期结果" prop="result">
                    <a-textarea :autoSize="{ minRows: 4 }" v-model="formData.result" :maxLength="200"
                        placeholder="请输入（最多200个字）" />
                </a-form-model-item>
                <a-form-model-item label="预计起止时间" prop="dateRange">
                    <a-range-picker valueFormat="YYYY-MM-DD" v-model="formData.dateRange" style="width: 100%;" />
                </a-form-model-item>
                <!-- <a-form-model-item label="添加课题组内成员">
                    <a-select  v-model="">

                    </a-select>
                </a-form-model-item> -->
                <!-- <a-form-model-item label="邀请课题组外成员" prop="memberUserEmails">
                    <a-textarea :autoSize="{ minRows: 4 }" v-model="formData.memberUserEmails" :maxLength="200"
                        placeholder="请输入用户邮箱，若邀请多名用户，邮箱以英文逗号 “,” ， 例如： 123@qq.com,234@qq.com " />
                </a-form-model-item> -->
                <a-form-model-item label="添加课题组成员">
                    <a-tooltip :title="!formData.researchGroupId ? '请先选择课题组' : ''">
                        <a-button type="primary" :disabled="!formData.researchGroupId" @click="handleAddMemberFromResearchTeamButtonClick"> <a-icon type="user-add" /> 选择成员 </a-button>
                    </a-tooltip>
                    <div v-if="selectedResearchGroupUsers.length > 0">
                        <div>
                            <div style="margin: 6px 0; font-weight: bold;">
                                共 {{ selectedResearchGroupUsers.length }} 位
                            </div>
                            <div class="modal-table-ctn">
                            <a-table  rowKey="userId" :columns="selectUserTableColumn" size="small"
                                :data-source="selectedResearchGroupUsers"
                                :pagination="false" bordered :scroll="{ y: 500 }">
                            </a-table>
                        </div>
                        </div>
                    </div>
                </a-form-model-item>
                <a-form-model-item label="邀请课题组外成员" prop="memberUserEmails">
                    <!-- <a-textarea :autoSize="{ minRows: 4 }" v-model="formData.memberUserEmails" :maxLength="200"
                        placeholder="请输入用户邮箱，若邀请多名用户，邮箱以英文逗号 “,” ， 例如： 123@qq.com,234@qq.com " /> -->
                    <div style="display: flex; justify-content: center; align-items: center;">

                        <div style="width: 480px; margin-left: 0px;">
                            <a-select style="width: 100%;" show-search :value="id" @search="handleUserSearch"
                                @change="handleUserSelectChange" option-label-prop="label" placeholder="输入用户名称或手机号、邮箱搜索"
                                :filter-option="false" :showArrow="false">

                                <a-select-option v-for="(user, userIndex) in searchList" :key="user.userId"
                                    :disabled="selectedIdList.indexOf(user.userId) !== -1 || memberIdList.indexOf(user.userId) !== -1 ">
                                    <!-- {{  user.name  }} -->
                                    <div style="display: flex; ">
                                        <div>
                                            <DefaultAvatar :url="user.photoMedia" :size="28" :name="user.name"></DefaultAvatar>
                                        </div>
                                        <div style="margin-left: 10px;width: 0; flex: 1;">
                                            <div style="word-wrap: break-word;">
                                                {{ user.name }}
                                            </div>
                                            <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
                                                :title="user.phone">
                                                手机号码：{{ user.phone }}
                                            </div>
                                            <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
                                                :title="user.email">
                                                邮箱： {{ user.email }}
                                            </div>
                                        </div>
                                        <div v-if="selectedIdList.indexOf(user.userId) !== -1"
                                            style="color: #1890FF;margin-left: 10px;background: #fff;">
                                            已添加
                                        </div>
                                       <!--  <div v-if="memberIdList.indexOf(user.userId) !== -1"
                                            style="color: #1890FF;margin-left: 10px;background: #fff;">
                                            已在课题组中
                                        </div> -->
                                        <div v-if="!user.email" style="color: #1890FF;margin-left: 10px;background: #fff;">
                                            用户未绑定邮箱
                                        </div>
                                    </div>
                                </a-select-option>
                                <template slot="notFoundContent">
                                    <a-spin v-if="fetching" size="small" />
                                    <a-empty v-else-if="!searchVal" description="输入名称或手机号、邮箱进行搜索"></a-empty>
                                    <a-empty v-else-if="!fetching && searchVal && getFilterOption.length == 0"></a-empty>
                                </template>
                                <!-- <a-empty v-if="!fetching && getFilterOption.length === 0 && searchVal"/> -->
                            </a-select>
                        </div>
                    </div>
                    <div style="margin-bottom: 16px;" v-if="list.length > 0" class="table-ctn">
                        <div style="margin: 6px 0; font-weight: bold;">
                            共邀请 {{ list.length }} 位
                        </div>
                        <a-table rowKey="userId" :columns="tableColumns" :data-source="list" size="small"
                            :pagination="false" :scroll="{ y: 300 }">
                            <template slot="avatar" slot-scope="text, record, index">
                                <div>
                                    <DefaultAvatar :url="record.photoMedia" :name="record.name"></DefaultAvatar>
                                </div>
                            </template>
                            <template slot="action" slot-scope="text, record, index">
                                <a-button type="link" @click="list.splice(index, 1)"> 删除 </a-button>
                            </template>
                        </a-table>
                    </div>
                </a-form-model-item>

            </a-form-model>
            <a-alert message=" 根据单位相关规定，请勿在此创建军队项目，军队项目沿用纸质记录" banner />

        </a-spin>
        <template slot="footer">
            <div class="btns-ctn">
                <a-button :disabled="loading" class="concel-btn" @click="handleCancel">
                    取消
                </a-button>
                <a-button :disabled="loading" type="primary" class="confirm-btn" @click="handleConfirm">
                    确定
                </a-button>
            </div>
        </template>
    </a-modal>
    <a-modal :visible="selectMemberFromResearchGroupModalVisible" title="选择课题组成员"
            @cancel="selectMemberFromResearchGroupModalVisible = false" :width="680">
            <a-spin :spinning="isResearchTeamMemeberLoading" tip="正在加载">
                <div class="modal-table-ctn">
                    <a-table  rowKey="userId" :columns="selectUserTableColumn" size="small"
                        :data-source="researchTeamUserList"
                        :row-selection="{ selectedRowKeys: selectedUserRowKeys, onChange: onUserSelectChange, }"
                        :pagination="false" bordered :scroll="{ y: 500 }">
                    </a-table>
                </div>
            </a-spin>
            <template slot="footer">
                <div style="display: flex; justify-content: center;">
                    <a-button type="primary" :loading="isAddingUserMember"
                        :disabled="isAddingUserMember"
                        @click="handleSubmitResearchTeamMember"> 确定 </a-button>
                    <a-button :loading="isAddingUserMember" :disabled="isAddingUserMember"
                        @click="selectMemberFromResearchGroupModalVisible = false; "> 取消 </a-button>
                </div>
            </template>
        </a-modal>
</div>
</template>

<script>
import { message } from 'ant-design-vue';
import { watch, ref, computed } from 'vue-demi';
import { addProject, getResearchGroupList, getResearchGroupUserList as getResearchGroupUserListFoProject } from '../../../api/projectService';

import { researchGroupFindUser, getResearchGroupUserList } from '@/api/researchGroup.js'
import debounce from 'lodash/debounce';
import DefaultAvatar from '@/components/defaultAvatar.vue';
import store from '../../../store';

const tableColumns = [
    {
        title: '头像',
        dataIndex: 'avatar',
        scopedSlots: { customRender: "avatar" }
    },
    {
        title: '成员名称',
        dataIndex: 'name',
        scopedSlots: { customRender: "name" }
    },
    {
        title: '手机',
        dataIndex: 'phone',
        scopedSlots: { customRender: "name" }
    },
    {
        title: '邮箱',
        dataIndex: 'email',
        scopedSlots: { customRender: "email" }
    },
    {
        title: '操作',
        key: 'action',
        scopedSlots: { customRender: "action" }
    }
]
export default {
    props: {
        visible: {
            type: Boolean,
            required: true
        }
    },
    components: {
        DefaultAvatar
    },
    setup(props, context) {
        // 表单实例
        const formRef = ref(null);
        // 表单数据
        const formData = ref({
            name: '',   // 项目名
            objective: '',  // 目的
            result: '', // 预期结果
            memberUserEmails: '',   // 用户又想
            startTime: '',  // 开始时间
            endTime: '',    // 结束时间
            magazine: '',   // 期刊
            remark: '',  // 备注
            dateRange: [],  // 起始时间
            researchGroupId: undefined, // 归属的课题组
            headUserId: undefined,  // 负责人
        })
        // 取消操作
        const handleCancel = () => {
            context.emit('update:visible', false);
        }
        // 
        const loading = ref(false);
        // 规则
        const rules = ref({
            name: [{
                required: true,
                message: `请输入`,
                whitespace: true,
            }, {
                max: 50,
                message: '项目名称不能超过50个字符'
            }],
            researchGroupId: [
                {
                    required: true,
                    message: '请选择项目归属课题组'
                }
            ],
            headUserId: [
                {
                    required: true,
                    message: '请选择项目负责人'
                }
            ],
            magazine: [{
                max: 200,
                message: '不能超过200个字符'
            }],
            objective: [{
                required: true,
                message: `请输入`,
                whitespace: true
            }, {
                max: 200,
                message: '不能超过200个字符'
            }],
            result: [{
                required: true,
                message: `请输入`,
                whitespace: true,
                //max: 200
            }, {
                max: 200,
                message: '不能超过200个字符'
            }],
            dateRange: [{
                required: true,
                message: `请选择`
            }],
            memberUserEmails: [{
                validator: (_rule, value, callback) => {
                    if (!value.toString().trim()) {
                        callback();
                    }
                    else if (value.toString().indexOf("，") !== -1) {
                        callback(new Error(`请使用英文逗号 “,”`));
                    }
                    else {
                        const re = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
                        const emails = value.toString().split(",");
                        emails.forEach(element => {
                            if (!re.test(element.toString().trim())) {
                                callback(new Error(`“${element.toString().trim()}” 不为邮箱`));
                            }
                        });
                        callback();
                    }
                }
            }]
        })

        // 获取课题组列表
        const researchGroupList = ref([]);

        // 是否正在获取负责人列表
        const isHeadListLoading = ref(false);

        // 负责人列表
        const headList = ref([])

        // 获取课题组下可以当负责人的成员列表
        const getHeadUserList = async (params) => {
            isHeadListLoading.value = true;
            const res = await getResearchGroupUserListFoProject(params);
            if (res.success) {
                headList.value = res.data;
                formData.value.headUserId = headList.value.length > 0 ? headList.value[0].userId : undefined
            }
            else {
                headList.value = []
                formData.value.headUserId = undefined
            }
            isHeadListLoading.value = false;
        }

        // 课题组列表
        const initResearchGroupList = async () => {
            loading.value = true;
            const res = await getResearchGroupList({

            });
            if (res.success) {
                researchGroupList.value = res.data;
                loading.value = false;
            }
        }

        watch(() => props.visible, (newVal) => {
            if (newVal) {
                formData.value = {
                    name: '',   // 项目名
                    objective: '',  // 目的
                    result: '', // 预期结果
                    memberUserEmails: '',   // 用户又想
                    startTime: '',  // 开始时间
                    endTime: '',    // 结束时间
                    magazine: '',   // 期刊
                    remark: '',  // 备注
                    dateRange: [],  // 起始时间
                    researchGroupId: undefined, // 归属的课题组
                    headUserId: undefined,  // 负责人
                    researchGroupUserIds:[],    // 来自课题组的成员id 
                    userIds: [],    // 来自课题组外的用户Id
                }
            }
            formRef.value && formRef.value.resetFields();
            list.value = [];
            selectedResearchGroupUsers.value = [];
            initResearchGroupList();

        })
        // 确认
        const handleConfirm = () => {
            formRef.value && formRef.value.validate(async (valid) => {
                if (valid) {
                    const submitData = JSON.parse(JSON.stringify(formData.value));
                    submitData.startTime = formData.value.dateRange[0];
                    submitData.endTime = formData.value.dateRange[1];
                    //submitData.memberUserEmails = submitData.memberUserEmails.toString().trim();
                    submitData.userIds = list.value.map(user => user.userId);   // 邀请成员列表
                    submitData.researchGroupUserIds = selectedResearchGroupUsers.value.map(user => user.userId);    // 选择的课题组成员
                    loading.value = true;
                    const res = await addProject(submitData);
                    if (res.code === 200 || res.code === 204) {
                        message.success(res.message)
                        context.emit('onAddProject');
                        context.emit('update:visible', false);
                    }
                    loading.value = false;
                }
            })
        }

        const filterOption = (input, option) => {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        }

        // 课题组更改
        const handleResearchGroupChange = (val) => {
            formData.value.headUserId = undefined
            getHeadUserList({
                researchGroupId: val
            });
            // 课题组更改后，去除课题组成员
            selectedResearchGroupUsers.value = [];
        }

        // 负责人更改
        const handleHeadChange = (val) => {
            // 负责人更改后去除用户
            selectedResearchGroupUsers.value = selectedResearchGroupUsers.value.filter(user => user.userId != store.state.userInfo.userId && user.userId != formData.value.headUserId)
        }

        /**************************************************          邀请课题组外的成员                          ********************************/
        const list = ref([]); // 已选择的列表
        const searchList = ref([]); // 搜索的列表
        const id = ref(undefined); // 选择用户的id
        const fetching = ref(false); // 是否正在加载
        const searchVal = ref(""); // 搜索的关键词
        // 搜索用户请求
        const searchUser = debounce(async (val) => {
            searchVal.value = val;
            if (!val.toString()) {
                searchList.value = [];
                return;
            }
            fetching.value = true;
            const res = await researchGroupFindUser({
                userKey: val
            });
            if (res.success) {
                searchList.value = res.data.filter(user => user.userId != store.state.userInfo.userId);
            }
            fetching.value = false;
        }, 800);
        // 用户搜索
        const handleUserSearch = (val) => {
            searchVal.value = val.toString().trim();
            if (val.toString().trim()) {
                fetching.value = true;
            }
            else {
                fetching.value = false;
            }
            searchList.value = [];
            //console.log(val)
            searchUser(val.toString().trim());
        };
        // 选择改变
        const handleUserSelectChange = (val, option) => {
            //console.log(searchList.value.filter(user => user.id === val)[0])
            //list.value.push(JSON.parse(JSON.stringify(option)));
            list.value.push(JSON.parse(JSON.stringify(searchList.value.filter(user => user.userId === val)[0])))
            id.value = undefined;
            searchVal.value = undefined;

        };

        // 已选择用户的id列表
        const selectedIdList = computed(() => {
            return list.value.map(user => user.userId);
        })
        // 已在项目组内的用户Id
        const memberIdList = computed(() => {
            return [store.state.userInfo.userId];
        })
        // 过滤已选择的用户后的列表
        const getFilterOption = computed(() => {
            const ids = list.value.map(user => user.userId);
            return searchList.value.filter(user => {
                return ids.indexOf(user.userId) === -1;
            });
        });


        const isSubmitting = ref(false); // 是否正在提交



        watch(() => props.visible, (val) => {
            if (val) {
                searchList.value = [];
                searchVal.value = '';
                list.value = [];
            }
        })
        /************************************************************************************************************************************/

        /********************          选择课题组内的成员                    **********/
        const selectMemberFromResearchGroupModalVisible = ref(false);	// 从课题组内选择成员对话框可见变量

        const researchTeamUserList = ref([]);

        const isResearchTeamMemeberLoading = ref(false);	// 是否正在加载

        const isAddingUserMember = ref(false);	// 是否正在提交成员

        watch(() => selectMemberFromResearchGroupModalVisible.value, async (newVal) => {
            if (newVal) {
                isAddingUserMember.value = false;
                isResearchTeamMemeberLoading.value = true;
                selectedUserRowKeys.value = selectedResearchGroupUsers.value.map(user => user.userId);
                const res = await getResearchGroupUserList({
                    researchGroupId: formData.value.researchGroupId
                });
                if(res.success) {
                    researchTeamUserList.value = res.data.detail.filter(user => user.userId != store.state.userInfo.userId && user.userId != formData.value.headUserId);
                }
                isResearchTeamMemeberLoading.value = false;
              /*   const res = await getProjectUserByResearchGroupUser({
                    projectId: props.projectId
                })
                if (res.success) {
                    researchTeamUserList.value = res.data;
                }
                isResearchTeamMemeberLoading.value = false; */
            }

        })

        // 用户columns
        const selectUserTableColumn = [
            {
                title: '用户',
                dataIndex: 'userName',
                slotScope: {
                    scopedSlots: { customRender: "name" }
                }
            },
            {
                title: '手机',
                dataIndex: 'phone'
            },
            {
                title: '邮箱',
                dataIndex: 'email'
            }
        ]

        const selectedUserRowKeys = ref([]);

        // 用户选择改变
        const onUserSelectChange = (val) => {
            selectedUserRowKeys.value = val;
        }
        // 添加课题组成员按钮点击事件
        const handleAddMemberFromResearchTeamButtonClick = () => {
            selectMemberFromResearchGroupModalVisible.value = true;
        }

        const selectedResearchGroupUsers = ref([])

        // 添加课题组用户确认事件
        const handleSubmitResearchTeamMember = async () => {
            isAddingUserMember.value = true;
            selectedResearchGroupUsers.value = researchTeamUserList.value.filter(user => selectedUserRowKeys.value.indexOf(user.userId) != -1);
            selectMemberFromResearchGroupModalVisible.value = false;
          /*   const res = await addResearchGroupMemberToProject({
                userIds: selectedUserRowKeys.value,
                projectId: props.projectId
            });
            if (res.success) {
                message.success('成功添加');

                selectMemberFromResearchGroupModalVisible.value = false;
                context.emit('onAddMemberFromResearchGroup'
                const dres = await getProjectUserManager({
                    projectid: props.projectId
                });
                if (dres.code === 200) {
                    userList.value = dres.data.data;
                }
            } */
            isAddingUserMember.value = false;

        }


        /****************************************************************************/
        
        return {
            handleCancel,
            formRef,
            formData,
            rules,
            handleConfirm,
            loading,
            researchGroupList,
            filterOption,
            headList,
            isHeadListLoading,
            handleResearchGroupChange,
            list,
            searchList,
            id,
            handleUserSearch,
            handleUserSelectChange,
            tableColumns,
            getFilterOption,
            fetching,
            searchVal,
            selectedIdList,
            memberIdList,
            isSubmitting,
            selectMemberFromResearchGroupModalVisible,
            selectUserTableColumn,
            selectedUserRowKeys,
            onUserSelectChange,
            researchTeamUserList,
            handleAddMemberFromResearchTeamButtonClick,
            isResearchTeamMemeberLoading,
            isAddingUserMember,
            handleSubmitResearchTeamMember,
            selectedResearchGroupUsers,
            handleHeadChange
        }
    }
}
</script>

<style lang="less" scoped>
.btns-ctn {
    text-align: center;

    .concel-btn {
        background: #eeeeee;
    }
}
</style>

<style lang="less" scoped>
.table-ctn {
    /deep/ .ant-table-thead {
        background: #F0F5FF;
    }

    /deep/ .ant-table-small>.ant-table-content>.ant-table-body {
        margin: 0px;
    }

    /deep/ .ant-table-row {
        td {
            padding: 7px;
        }
    }

    /deep/ .ant-table-row:hover {
        td:hover {
            .edit-cell {
                border: 1px solid #e4e4e4;
                background: #FFFFFF;

            }
        }
    }
}
</style>

<style lang="less" scoped>
.modal-table-ctn {
	/deep/ .ant-table-thead {
        background: #F0F5FF;
    }

    /deep/ .ant-table-small > .ant-table-content > .ant-table-body {
        margin: 0px;
    }

    /deep/ .ant-table-row {
        td {
            padding: 7px;
        }
    }

    /deep/ .ant-table-row:hover {
        td:hover {
            .edit-cell {
                border: 1px solid #e4e4e4;
                background: #FFFFFF;

            }
        }
    }
}
</style>